<template>
  <default-layout :header-title="$t('chat')" :hide-header="true">
    <p class="w-title">{{ $t('chat') }}</p>
    <div class="px-3">
      <div class="primary-block primary-nav-list">
        <router-link to="/payment-number-contract" class="nav-item">
          <div class="icon-box"><img src="/img/salary.svg"></div>
          <p class="ms-3 mb-0">{{ $t('pay') }} </p>
        </router-link>
        <a @click="callToUsClicked" href="tel:797" class="nav-item">
          <div class="icon-box">
            <icon icon-name="phone-icon"></icon>
          </div>
          <p class="ms-3 mb-0">{{$t('callUs')}}</p>
        </a>
        <a href="https://wa.me/77770089070?text" class="nav-item">
          <div class="icon-box">
            <icon icon-name="whatsapp-icon"></icon>
          </div>
          <p class="ms-3 mb-0">{{$t('writeToWhatsapp')}}</p>
        </a>

        <a href="https://wa.me/77770089070?text" class="nav-item">
          <div class="icon-box">
            <icon icon-name="whatsapp-icon"></icon>
          </div>
          <p class="ms-3 mb-0">{{$t('writeToWhatsapp')}}</p>
        </a>


        <a href="https://europrotocol.polisonline.kz/support/settlement?partner=homebank" class="nav-item">
          <div class="icon-box">
            <icon icon-name="document-icon"></icon>
          </div>
          <p class="ms-3 mb-0">Онлайн-урегулирование</p>
        </a>

        <router-link @click.native="chatItemClicked(item)" :to="item.link" class="nav-item" v-for="(item, index) in chatNavigation" :key="index">
          <div class="icon-box">
            <icon :icon-name="item.icon"></icon>
          </div>
          <p class="ms-3 mb-0">{{ item.name }}</p>
        </router-link>
      </div>
    </div>
  </default-layout>
</template>

<script>
import eventLogger from '../../services/eventLogger'
export default {
  name: "Chat",
  data() {
    return {
      chatNavigation: [
        {name: this.$i18n.t('chatUs'), icon: 'message-icon', link: 'chat'},
        {name: this.$i18n.t('insuredCase'), icon: 'sos-icon', link: 'sos-navigation'},
        {name: this.$i18n.t('leaveFeedback'), icon: 'heart-icon', link: 'send-single-feedback'},
        {name: this.$i18n.t('aboutUs'), icon: 'info-icon', link: 'about-us'},
      ]
    }
  },
  methods: {
    whatsAppClicked() {
      eventLogger('select_chat_to_whatsapp')
      window.location = 'https://wa.me/77770089070?text&app_absent=0'
    },
    callToUsClicked() {
      eventLogger('select_call_to_us')
    },
    chatItemClicked(item) {
      if (item.link === 'chat') {
        eventLogger('select_chat_with_us')
      } if (item.link === 'send-single-feedback') {
        eventLogger('select_leave_feedback')
      }
    }
  },
  mounted(){
    eventLogger('view_chat_tab')
  },
  computed: {
    isAndroid() {
      return navigator.userAgent.match(/Android/i);
    },
  }
}
</script>

<style scoped>

</style>
